@import "../../styles//variables";
@import "../../styles/buttons";

// Fav
.fav {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 80px;
}

.fav__btn {
  width: 100%;
  font-weight: 300;
  font-size: 1.6rem;
}

@media (min-width: 900px) {
  .fav__btn {
    width: 35%;
  }
}

