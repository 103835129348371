@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lowerContainer{max-width:100%}@media(min-width: 900px){.lowerContainer{max-width:90%;margin:0 auto}}@media(min-width: 1440px){.lowerContainer{max-width:80%;margin:0 auto}}
.title{margin-top:30px}.title__text{font-family:"Moon-Regular";text-shadow:4px 4px 5px #000;font-size:10vw;font-weight:100;margin-bottom:15px;text-align:center}@media(min-width: 900px){.title__text{font-size:5vw}}
.btn{padding:8px 14px;border:2px solid transparent;cursor:pointer;font-family:"Moon-Regular";letter-spacing:1px;border-radius:2px;color:#4d5261;background-color:#f2f2f2;transition:all 123ms;font-size:.75rem;box-shadow:0 2.8px 2.2px rgba(0,0,0,.028),0 6.7px 5.3px rgba(0,0,0,.04),0 12.5px 10px rgba(0,0,0,.05),0 22.3px 17.9px rgba(0,0,0,.06),0 41.8px 33.4px rgba(0,0,0,.072),0 100px 80px rgba(0,0,0,.1)}.btn:hover{background:#9aa4c2;color:#f2f2f2}.picture{max-width:90%;margin:0 auto}.picture__bar{display:flex;flex-direction:column;justify-content:space-between;align-items:center}.picture__date{width:100%;display:flex;justify-content:space-evenly}.picture__btn-text{font-family:"Moon-Regular";letter-spacing:1px}.picture__holder{order:-1;flex:1 0 100%;max-width:90%;margin:0 auto;margin-bottom:15px}.picture__img{max-width:100%;border-radius:2px;height:auto}@media(min-width: 900px){.picture__holder{max-width:40%;margin:0 auto;order:0}}
@font-face{font-family:"Moon-Regular";src:local("Moon-Regular"),url(/static/media/Moon2.0-Regular.beaab266.otf) format("woff")}.desc{max-width:90%;margin:0 auto}.desc__text{color:#c8cedf;font-family:"Moon-Regular";line-height:2;letter-spacing:1px;text-align:justify}
.btn{padding:8px 14px;border:2px solid transparent;cursor:pointer;font-family:"Moon-Regular";letter-spacing:1px;border-radius:2px;color:#4d5261;background-color:#f2f2f2;transition:all 123ms;font-size:.75rem;box-shadow:0 2.8px 2.2px rgba(0,0,0,.028),0 6.7px 5.3px rgba(0,0,0,.04),0 12.5px 10px rgba(0,0,0,.05),0 22.3px 17.9px rgba(0,0,0,.06),0 41.8px 33.4px rgba(0,0,0,.072),0 100px 80px rgba(0,0,0,.1)}.btn:hover{background:#9aa4c2;color:#f2f2f2}.fav{max-width:90%;margin:0 auto;margin-bottom:20px;margin-top:80px}.fav__btn{width:100%;font-weight:300;font-size:1.6rem}@media(min-width: 900px){.fav__btn{width:35%}}
.modal__btnContainer{display:flex;justify-content:center;align-items:center;margin:0 15px;margin-bottom:50px}.modal__title{font-size:3rem;margin:15px 15px;padding:0px;font-family:"Moon-Regular";letter-spacing:2px}.modal__text{font-family:"Moon-Regular";letter-spacing:2px}@media(min-width: 960px){.modal__btnContainer{display:flex;justify-content:center;align-items:center;margin:0 15px;margin-bottom:15px}}
.thumbnail{display:flex;flex-direction:column;justify-content:flex-start;align-items:center;text-align:center;height:auto;line-height:10px;transition:all 123ms}.thumbnail:hover{color:#22a589}.thumbnail__title{font-size:1rem;font-weight:500;margin-top:2px;margin-bottom:8px;font-family:"Moon-Regular";letter-spacing:1px;line-height:1.2}.thumbnail__date{font-family:"Moon-Regular";letter-spacing:1px;font-size:14px}.thumbnail__imgbox{display:flex;flex-direction:column;justify-content:flex-end;align-items:center;height:120px}.image{max-width:80%;border-radius:3px;height:100px;object-fit:cover;cursor:pointer}.remove-image{background:#333;display:inline;position:absolute;top:-10px;right:0px;border-radius:10em;padding:2px 6px 3px;text-decoration:none;font:700 21px/20px sans-serif;background:#555;border:3px solid #fff;color:#fff;box-shadow:0 2px 6px rgba(0,0,0,.5),inset 0 2px 4px rgba(0,0,0,.3);text-shadow:0 1px 2px rgba(0,0,0,.5);transition:background .5s;cursor:pointer}.remove-image:hover{background:#e54e4e;color:#fff;padding:3px 7px 5px;top:-11px;right:-1px}.remove-image:active{background:#e54e4e;top:-10px;right:-1px}.image:hover~.remove-image{background:#e54e4e;color:#fff;padding:3px 7px 5px;top:-11px;right:-1px}.thumbnail--delete:hover{color:#e54e4e}
.favs{max-width:90%;margin:15px auto;border-top:1px solid #9ba4c1}.favs__btnContainer{display:flex;justify-content:space-between;margin-bottom:25px;margin-top:5px}.favs__btn{width:45%;font-size:1rem}.favs__btn--delete{background-color:red;color:#fff}.favs__btn--delete:hover{background-color:#b30000}.favs__btn--active{background-color:#e9cf9f;color:#14161e}.favs__btn--cancel{background-color:#9aa4c2;color:#fff;margin-left:20px}.favs__btn--cancel:hover{background-color:#23293a}.favs__title{margin-bottom:15px;font-weight:300;font-size:2.4rem}.favs__container{display:grid;grid-template-columns:repeat(auto-fit, minmax(100px, 0.1fr));grid-column-gap:10px;-webkit-column-gap:10px;column-gap:10px;grid-row-gap:15px;row-gap:15px;justify-content:center;margin-top:30px}.favs__container--empty{display:flex;flex-direction:row;justify-content:center;font-family:"Moon-Regular";letter-spacing:1px}@media(min-width: 960px){.favs__container{justify-content:left;margin-top:80px}}
.homepage{display:flex;justify-content:center;align-items:center;height:100vh}.blocks{height:100vh;display:flex;align-items:center;position:relative;justify-content:center}.block{--sz: 8vmin;--tX: 0;--animation: 700ms cubic-bezier(0.3, 0.5, 0.4, 0.9) infinite alternate-reverse;--hm: 4.5vmin;height:var(--sz);width:var(--sz);background-image:var(--bg);border-radius:50%;transform:translateX(var(--tX));mix-blend-mode:lighten}.orange{--bg: linear-gradient(-50deg, #fbab7e 0%, #f7ce68 100%);margin-right:var(--hm);-webkit-animation:attract-orange var(--animation);animation:attract-orange var(--animation)}.blue{--bg: linear-gradient(50deg, #00bfd5 0%, #c5f5ff 100%);margin-left:var(--hm);-webkit-animation:attract-blue var(--animation);animation:attract-blue var(--animation)}@-webkit-keyframes attract-orange{to{transform:translateX(calc(var(--sz) + calc(var(--hm) / 4)))}}@keyframes attract-orange{to{transform:translateX(calc(var(--sz) + calc(var(--hm) / 4)))}}@-webkit-keyframes attract-blue{to{transform:translateX(calc(var(--sz) * -1 - calc(var(--hm) / 4)))}}@keyframes attract-blue{to{transform:translateX(calc(var(--sz) * -1 - calc(var(--hm) / 4)))}}
.container{width:100%;height:100vh;font-family:"Moon-Regular";letter-spacing:1px;display:flex}.mp__container{display:flex;flex-direction:column;max-width:50%;margin:auto auto}.mp__title{font-size:6rem}.mp__text{margin:10px 0}@media(min-width: 900px){.mp__title{font-size:12rem}}
@font-face{font-family:"Moon-Bold";src:local("Moon-Bold"),url(/static/media/Moon2.0-Bold.e1c9d28f.otf) format("woff")}@font-face{font-family:"Moon-Light";src:local("Moon-Light"),url(/static/media/Moon2.0-Light.14e8e211.otf) format("woff")}@font-face{font-family:"Moon-Regular";src:local("Moon-Regular"),url(/static/media/Moon2.0-Regular.beaab266.otf) format("woff")}*{box-sizing:border-box;margin:0;padding:0;text-align:center}html{font-family:sans-serif;font-size:1rem}a{color:none;text-decoration:none}a:hover{color:currentColor;text-decoration:none}body{font-family:sans-serif;background-image:#000;background-attachment:fixed;background-size:cover;max-height:300px;background-repeat:no-repeat;background-position:center;font-weight:300;height:100%;width:100%;color:#f2f2f2;background-color:rgba(20,22,30,.65);background-blend-mode:multiply}
