
// Title
.title {
  margin-top: 30px;
}

.title__text {
  font-family: "Moon-Regular";
  text-shadow: 4px 4px 5px black;
  font-size: 10vw;
  font-weight: 100;
  margin-bottom: 15px;
  text-align: center;
  
}



@media (min-width: 900px) {
  .title__text {
    font-size: 5vw;
  }
  
  
}
