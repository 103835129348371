// Variables

$primary-font-color: #f2f2f2;
$primary-bg-color: #14161e;

$secondary-font-color: #b8bfd6;

// Btn Variables
$primary-btn-color: #f2f2f2;
$secondary-btn-color: #4d5261;
$hover-btn-color: #9aa4c2;
$active-btn-color: #e9cf9f;