@import "../../styles/variables";


// Favs
.favs {
    max-width: 90%;
    margin: 15px auto;
  
    border-top: 1px solid #9ba4c1;
  }
  
  .favs__btnContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 5px;
  }
  
  .favs__btn {
    width: 45%;
    font-size: 1rem;
  }
  
  .favs__btn--delete {
    background-color: red;
    color: white;
  }

  .favs__btn--delete:hover {
    background-color: darken(red, 15%);
  }
  
  .favs__btn--active{
    background-color: $active-btn-color;
    color: $primary-bg-color;
  }


  .favs__btn--cancel {
    background-color: $hover-btn-color;
    color: white;
    margin-left: 20px;

  }
  .favs__btn--cancel:hover {
    background-color: darken($hover-btn-color, 50%);
  }

   .favs__title {
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 2.4rem;
  }
  
  .favs__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 0.1fr));
    column-gap: 10px;
    row-gap: 15px;
  
    justify-content: center;

    margin-top: 30px;
  }

  .favs__container--empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Moon-Regular";
    letter-spacing: 1px;
  }

  @media (min-width: 960px) {

    .favs__container {
      justify-content: left;
      margin-top: 80px;
    }
  }
  