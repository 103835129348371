@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");

@font-face {
  font-family: "Moon-Bold";
  src:local("Moon-Bold"), url(../fonts/Moon2.0-Bold.otf) format("woff");
}
@font-face {
  font-family: "Moon-Light";
  src:local("Moon-Light"), url(../fonts/Moon2.0-Light.otf) format("woff");
}
@font-face {
  font-family: "Moon-Regular";
  src:local("Moon-Regular"), url(../fonts/Moon2.0-Regular.otf) format("woff");
}


@import "./variables";

// Base
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
}

html {
  font-family: sans-serif;
  font-size: 1rem;
}

a {
  color: none;
  text-decoration: none;
}

a:hover {
  color: currentColor;
  text-decoration: none;
}

body {
  font-family: sans-serif;
  background-image: black;
  background-attachment: fixed;
  background-size: cover;
  max-height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: 300;
  height: 100%;
  width: 100%;
  color: $primary-font-color;
  background-color: rgba($primary-bg-color, 0.65);
  background-blend-mode: multiply;

}



